import {
  FETCH_RECRUIT_TOKEN,
  INIT_ACTION,
  FETCH_ROOM_DETAILS,
  SET_IR_PARENT,
  SET_CHAT_MESSAGE_DATA,
  SET_MESSAGE_REF_DATA,
  SET_PARTICIPANT_DATA,
  SET_PARTICIPANT_REF_DATA,
  SET_SIGNAL_DATA,
  SET_SELF_NOTIFICATION_DATA,
  SET_SIGNAL_REF_DATA,
  JOIN_INTERVIEW_ROOMS,
  GET_RECRUIT_CANDIDATE_DETAILS,
  SET_LOADING_TIME,
  SET_PRIVATE_CHAT_MESSAGE_DATA,
  SET_PRIVATE_MESSAGE_REF_DATA,
  UPDATE_SELF_NOTIFICATIONS_LIST,
  SET_VOIP_STATUS_DATA,
  SET_VOIP_STATUS_REF_DATA,
} from './constants';

export function fetchTokensAction({ jwt_token, id_token, custom_token, logEvent }) {
  return {
    type: FETCH_RECRUIT_TOKEN,
    jwt_token,
    id_token,
    custom_token,
    logEvent,
  };
}
export function initAction({ sessionId, parentFrame, participantRole, logEvent, meeting_id }) {
  return {
    type: INIT_ACTION,
    sessionId,
    parentFrame,
    participantRole,
    logEvent,
    meeting_id,
  };
}

export function fetchRoomDetails(roomId) {
  return {
    type: FETCH_ROOM_DETAILS,
    roomId,
  };
}

export function setIRParentAction(parentValue, candidateOldId) {
  return {
    type: SET_IR_PARENT,
    parentValue,
    candidateOldId: parseInt(candidateOldId, 10),
  };
}

export function setMessageRefDataAction(messageRefData) {
  return {
    type: SET_MESSAGE_REF_DATA,
    messageRefData,
  };
}

export function setPrivateMessageRefDataAction(privateMessageRefData) {
  return {
    type: SET_PRIVATE_MESSAGE_REF_DATA,
    privateMessageRefData,
  };
}

export function setSignalRefDataAction(signalRefData) {
  return {
    type: SET_SIGNAL_REF_DATA,
    signalRefData,
  };
}

export function setParticipantRefDataAction(participantRefData) {
  return {
    type: SET_PARTICIPANT_REF_DATA,
    participantRefData,
  };
}

export function setParticipantDataAction(participantData, uniqueParticipantsData) {
  return {
    type: SET_PARTICIPANT_DATA,
    participantData,
    uniqueParticipantsData,
  };
}

export function setChatMessageDataAction(chatMessageDataList) {
  return {
    type: SET_CHAT_MESSAGE_DATA,
    chatMessageDataList,
  };
}

export function setPrivateChatMessageDataAction(privateChatMessageDataList) {
  return {
    type: SET_PRIVATE_CHAT_MESSAGE_DATA,
    privateChatMessageDataList,
  };
}

export function setSignalDataAction(signalDataList) {
  return {
    type: SET_SIGNAL_DATA,
    signalDataList,
  };
}

export function setSelfNotificationDataAction(selfNotificationData) {
  return {
    type: SET_SELF_NOTIFICATION_DATA,
    selfNotificationData,
  };
}

export function updateSelfNotificationsListAction(updatedSelfNotificationsList) {
  return {
    type: UPDATE_SELF_NOTIFICATIONS_LIST,
    updatedSelfNotificationsList,
  };
}

export function joinInterviewRoomAction({
  sessionId,
  meeting_id,
  participantId,
  urlRecordingParam,
  fallbackVideoProvider,
  logEvent,
}) {
  return {
    type: JOIN_INTERVIEW_ROOMS,
    sessionId,
    meeting_id,
    participantId,
    urlRecordingParam,
    fallbackVideoProvider,
    logEvent,
  };
}

export function getRecruitCandidateDetailsAction(candidateOldId) {
  return {
    type: GET_RECRUIT_CANDIDATE_DETAILS,
    candidateOldId,
  };
}

export function setLoadingTimeAction(loadingTime) {
  return {
    type: SET_LOADING_TIME,
    loadingTime,
  };
}

export function setVoipStatusDataAction(voipStatusData) {
  return {
    type: SET_VOIP_STATUS_DATA,
    voipStatusData,
  };
}

export function setVoipStatusRefDataAction(voipStatusRefData) {
  return {
    type: SET_VOIP_STATUS_REF_DATA,
    voipStatusRefData,
  };
}
